<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ reseller.name }} - {{ $t("COMMON.ROLES") }}
      </span>
    </div>

    <role-list-table :filterReseller="reseller.id" />
  </div>
</template>

<script>
import RoleListTable from "../../RoleManagement/partials/RoleListTable.vue";

export default {
  name: "reseller-view-roles",

  components: { RoleListTable },

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
